import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import rightArrow from '../../assets/images/arrowRight.svg';
import leftArrow from '../../assets/images/arrowLeft.svg';
import { isBrowser } from '../../utils/globalFunctions';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    content: {
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 60,
        paddingBottom: 60
    },
    pageHandler: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pageNumber: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 17,
        marginRight: 24,
        marginLeft: 24
    },
    arrow: {
        width: 24
    }
};

const DocumentDialog = ({ isOpen, close, title, document }) => {
    const [numPages, setNumPages] = useState(0);
    const [renderedPageNumber, setRenderedPageNumber] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const { min, max } = Math;
    const pageWidth = isBrowser() ? window.innerWidth * 0.5 : 300;

    const onLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const onClose = () => {
        setNumPages(0);
        setPageNumber(1);
        setRenderedPageNumber(1);
        close();
    };

    return (
        <Dialog open={isOpen} onBackdropClick={onClose} fullWidth={false} maxWidth="xl">
            {/* <DialogContent style={styles.container}>
                <h2>{title}</h2>
                <div style={styles.pageHandler}>
                    <img
                        style={{ ...styles.arrow, opacity: pageNumber > 1 ? 1 : 0.5 }}
                        src={leftArrow} alt="left arrow"
                        onClick={() => setPageNumber(max(1, pageNumber - 1))}
                    />
                    <p style={styles.pageNumber}>{`${pageNumber}/${numPages}`}</p>
                    <img
                        style={{ ...styles.arrow, opacity: pageNumber < numPages ? 1 : 0.5 }}
                        src={rightArrow} alt="right arrow"
                        onClick={() => setPageNumber(min(pageNumber + 1, numPages))}
                    />
                </div>
                <Document file={document} onLoadSuccess={onLoadSuccess}>
                    <Page
                        key={pageNumber}
                        loading={(
                            <Page
                                key={renderedPageNumber}
                                pageNumber={renderedPageNumber}
                                width={pageWidth}
                            />
                        )}
                        pageNumber={pageNumber}
                        onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
                        width={pageWidth}
                    />
                </Document>
            </DialogContent> */}
        </Dialog>
    );
};

export default DocumentDialog;