import React, { useState, useEffect, useContext } from "react";
import { TranslationContext } from '../translation/translationContext';
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/insideNavbar';
import { LIGHT_BLUE, WHITE, PURPLE } from '../assets/colors';
import { insideNavbarHeight } from '../assets/dimensions';
import StandardButton from '../components/buttons/standardButton';
import papers from '../assets/images/papers.svg';
import docs from '../assets/documents';
import DocumentDialog from '../components/dialogs/documentDialog';
import SearchBar from '../components/texts/searchBar';
import { LIBRARY_CATEGORIES } from '../constants';

const styles = {
  container: {
    backgroundColor: LIGHT_BLUE,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: insideNavbarHeight + 48,
    paddingBottom: 800
  },
  title: {
    color: PURPLE,
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'Roboto'
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginBottom: 24
  },
  categoryTitle: {
    color: PURPLE,
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'Roboto'
  },
  searchBar: {
    marginBottom: 48,
  },
  buttonContainer: {
    display: 'flex',    
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 32
  },
  button: {
    marginRight: 8,
    marginLeft: 8,
    width: 250,
    height: 75,
    fontSize: 20
  },
  articlesContainer: {
    width: '60%',
  },
  article: {
    border: '1px solid #d3d3d3',
    width: '100%',
    display: 'flex',    
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 16,
    backgroundColor: WHITE,
    paddingTop: 16,
    paddingBottom: 16
  },
  documentIcon: {
    width: 25,
    height: 25,
    marginRight: 24
  },
  articleTitle: {
    fontFamily: 'Roboto',
    fontSize: 17,
    fontWeight: 400,
    color: '#1F96FF',
    textDecoration: 'underline',
  },
  scrollButton: {
    position: 'fixed',
    bottom: 50
  },
  notFoundText: {
    fontFamily: 'Roboto',
    fontSize: 17,
    fontWeight: 400,
  }
};

const LibraryPage = () => {
  const [filteredDocuments, setFilteredDocuments] = useState(docs);
  const [viewingDocument, setViewingDocument] = useState();
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [chosenCategory, setChosenCategory] = useState(Object.values(LIBRARY_CATEGORIES)[0]);
  const categories = Object.values(LIBRARY_CATEGORIES);

  const { translate } = useContext(TranslationContext);

  // const onPressArticle = (document) => {
  //   setViewingDocument(document);
  //   setShowDocumentDialog(true)
  // };

  const onClickCategory = (category) => {
    setChosenCategory(category);
  };

  const onCloseDialog = () => setShowDocumentDialog(false);

  useEffect(() => {
    const searchLower = searchPhrase.toLowerCase();
    const categorizedDocs = docs.filter(doc => doc.category === chosenCategory);
    setFilteredDocuments(categorizedDocs.filter(d => d.title.toLowerCase().includes(searchLower)));
  }, [searchPhrase, chosenCategory]);

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={'/library'} />
      <h1 style={styles.title}>{translate('library')}</h1>
      <p style={styles.subTitle}>{translate('libraryDescription')}</p>
      <h3 style={styles.categoryTitle}>{translate('category')}</h3>
      <div style={styles.buttonContainer}>
          {categories.map(category => {
            const isChosen = chosenCategory === category;
            return (
            <StandardButton
              onClick={() => onClickCategory(category)}
              style={{...styles.button, opacity: isChosen ? 1 : 0.5}}
              text={category}
            />
          )})}
      </div>
      <SearchBar
        placeholder={translate('searchLibrary')}
        style={styles.searchBar}
        value={searchPhrase}
        setValue={setSearchPhrase}
      />
      <DocumentDialog
        isOpen={showDocumentDialog}
        close={onCloseDialog}
        title={viewingDocument?.title}
        document={viewingDocument?.address}
      />
      {filteredDocuments.length ? (
        <div style={styles.articlesContainer}>
            {filteredDocuments.map(document => (
                <div key={document.title} style={styles.article}>
                    <img style={styles.documentIcon} src={papers} alt='document' />
                    {/* <a style={styles.articleTitle} onClick={() => onPressArticle(document)}>{document.title}</a> */}
                    <a
                      style={styles.articleTitle}
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{document.title}</a>
                </div>
            ))}
        </div> 
      ) : 
      <p style={styles.notFoundText}>{translate('foundNoArticles')}</p>
    }
    </Screen>
  )
}

export default LibraryPage;
