import { LIBRARY_CATEGORIES } from '../../constants';

import pfasPdf from './EU_and_global_legal_status_of_PFAS_April_2021.pdf';
import scipPdf from './Fact_sheet_-_SCIP_(Substances_of_Concern_In_articles,_as_such_or_in_complex_objects_(Products).pdf';
import euLegislationPdf from './Fact sheet - chemicals legislation in EU EEA.pdf';
import dyestuffsPdf from './Fact sheet - dyestuffs and pigments - uses and categories.pdf';
import internationalLegislationPdf from './Fact sheet - international chemicals legislation.pdf';
import overviewRestrictionsPdf from './Overview_of_restrictions_under_consideration_in_REACH_annex_XVII_March_2021.pdf';
import checklistLabsPdf from './checklist for laboratories  routine for test labs.pdf';
import glossaryTestPdf from './Fact sheet – a glossary for chemical test methods.pdf';
import contractTemplatePdf from './Fact sheet -  chemicals managment contract template.pdf';

// const pfas = { 
//     title: 'EU and global legal status of PFAS April 2021',
//     address: pfasPdf,
//     category: LIBRARY_CATEGORIES.LEGISLATION
// };

const scip = {
    title: 'Fact sheet - SCIP (Substances of Concern In articles, as such or in complex objects (Products)',
    address: scipPdf,
    category: LIBRARY_CATEGORIES.INTERNATIONAL_CHEMICALS_LEGISLATION,
    url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Fact_sheet_-_SCIP_(Substances_of_Concern_In_articles%2C_as_such_or_in_complex_objects_(Products).pdf?alt=media&token=9115d86a-e4ce-4e0b-835a-ba73819f6ce8'
};

// const euLegislation = {
//     title: 'Fact sheet - chemicals legislation in EU EEA',
//     address: euLegislationPdf,
//     category: LIBRARY_CATEGORIES.LEGISLATION
// };

const dyestuffs = {
    title: 'Fact sheet - dyestuffs and pigments - uses and categories',
    address: dyestuffsPdf,
    category: LIBRARY_CATEGORIES.EXPLANATORY,
    url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Fact%20sheet%20-%20dyestuffs%20and%20pigments%20-%20uses%20and%20categories.pdf?alt=media&token=0734f3e6-54ea-4980-80f0-936bffe7072a'
};

const internationalLegislation = {
    title: 'Fact sheet - international chemicals legislation',
    address: internationalLegislationPdf,
    category: LIBRARY_CATEGORIES.INTERNATIONAL_CHEMICALS_LEGISLATION,
    url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Fact%20sheet%20-%20international%20chemicals%20legislation.pdf?alt=media&token=08849e78-d03f-491f-98e3-90be77191579'
};

// const overviewRestrictions = {
//     title: 'Overview of restrictions under consideration in REACH annex XVII March 2021',
//     address: overviewRestrictionsPdf,
//     category: LIBRARY_CATEGORIES.LEGISLATION
// };

const checklistLabs = {
    title: 'Checklist for laboratories routine for test labs',
    address: checklistLabsPdf,
    category: LIBRARY_CATEGORIES.CHEMICAL_TESTING,
    url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/checklist%20for%20laboratories%20%20routine%20for%20test%20labs.pdf?alt=media&token=f4a20633-c79a-4cf5-8971-602eaa947de5'
};

const glossaryTest = {
    title: 'Fact sheet – a glossary for chemical test methods',
    address: glossaryTestPdf,
    category: LIBRARY_CATEGORIES.CHEMICAL_TESTING,
    url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Fact%20sheet%20%E2%80%93%20a%20glossary%20for%20chemical%20test%20methods.pdf?alt=media&token=4bbe309c-51d2-4f70-90c8-005a1565421f'
};

const contractTemplate = {
    title: 'Fact sheet - chemicals managment contract template',
    address: contractTemplatePdf,
    category: LIBRARY_CATEGORIES.CHEMICALS_MANAGEMENT,
    url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Fact%20sheet%20-%20%20chemicals%20managment%20contract%20template.pdf?alt=media&token=4dfd358e-5ed3-4404-976f-c7245270d78e'
};


export default [
    scip, dyestuffs, internationalLegislation, checklistLabs, glossaryTest, contractTemplate
];